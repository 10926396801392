import React, { Fragment } from 'react';
import { Box } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';

import Header from 'components/organisms/Header';
import GlobalStyles from 'setup/GlobalStyles';

type LayoutProps = {
  title?: string;
  description?: string;
  adCode?: string;
  subtitleText?: string;
  subtitleUrl?: string;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  title,
  subtitleText,
  subtitleUrl,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  return (
    <Fragment>
      <GlobalStyles />
      <Box variant="layout.wrapper">
        <Header
          siteTitle={title || data.site.siteMetadata?.title}
          {...{ subtitleText, subtitleUrl }}
        />
        <main>{children}</main>
      </Box>
    </Fragment>
  );
};

export default Layout;
