import routes from 'routes';
import { NavItem } from './types';

export const navItems: NavItem[] = [
  {
    label: 'Home',
    href: '/',
    key: '1',
  },
  {
    label: 'Changelog',
    href: routes.CHANGELOG,
    key: '2',
  },
  {
    label: 'About',
    href: routes.ABOUT,
    key: '3',
  },
  {
    label: 'Courses',
    href: 'https://www.learnsquared.com/courses',
    key: '4',
    isExternalLink: true,
  },
];
