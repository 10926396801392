/** @jsx jsx */
import { jsx, Box, Text, Flex, Link as ThemedLink } from 'theme-ui';
import { Link } from 'gatsby';

import Nav from 'components/molecules/Nav';
import { navItems } from 'components/molecules/Nav/consts';
import Inner from 'components/styles/Inner';
import routes from 'routes';
import { HeaderProps } from './types';

const Header: React.FC<HeaderProps> = ({
  siteTitle,
  subtitleText,
  subtitleUrl,
}) => (
  <Flex as="header" variant="header">
    <Inner>
      <Flex variant="header.content">
        <Box variant="header.labels">
          <Text as="p" variant="header.link">
            <Link to={routes.HOME}>{siteTitle}</Link>
          </Text>
          <Text as="span" variant="typo3">
            by
          </Text>
          {!!subtitleText && (
            <ThemedLink
              href={subtitleUrl}
              target="_blank"
              rel="noreferrer"
              variant="header.link"
            >
              {subtitleText}
            </ThemedLink>
          )}
        </Box>
        <Flex variant="header.nav">
          <Nav items={navItems} />
        </Flex>
      </Flex>
    </Inner>
  </Flex>
);

export default Header;
