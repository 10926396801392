import React from 'react';
import { Global } from '@emotion/core';

import useTheme from 'hooks/useTheme';
import 'assets/styles/reset.css';

const GlobalStyles: React.FC = () => {
  const { transition } = useTheme().transitions.default;

  return (
    <Global
      styles={(theme) => ({
        body: {
          fontSize: '1rem',
          lineHeight: 1.5,
          fontFamily: theme.fonts.museo,
        },
        button: {
          cursor: 'pointer',
        },
        a: {
          color: 'currentColor',
          textDecoration: 'none',
          transition,
        },
        svg: {
          fill: 'currentColor',
        },
      })}
    />
  );
};

export default GlobalStyles;
