/** @jsx jsx */
import { Link } from 'gatsby';
import { jsx, Flex } from 'theme-ui';

import { NavProps } from './types';

const Nav: React.FC<NavProps> = ({ items }) => (
  <Flex as="nav" variant="nav.link">
    {items.map(({ label, href, isExternalLink, ...rest }) =>
      isExternalLink ? (
        <a {...{ href, ...rest }} target="_blank" rel="noopener noreferrer">
          {label}
        </a>
      ) : (
        <Link to={href} {...rest} activeClassName="active">
          {label}
        </Link>
      ),
    )}
  </Flex>
);

export default Nav;
